.navbar-main {
  height: 60px;
  width: 100vw;
  background-color: rgb(255, 238, 207);
  /* background-color: #e2f1ff; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: sticky;
  top: 0;
  z-index: 100;
  border-bottom: 5px dashed black;
}
.navbar-logo {
  height: 55px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
}
.navbar-logo img {
  height: 50px;
  width: auto;
}
.navbar-logo a:hover {
  cursor: url(../../assets/images/Logo.svg), auto;
}
.navbar-logo a {
  border-radius: 50%;
}
.navbar-items {
  width: 60vw;
  display: flex;
  justify-content: flex-end;
}
.navbar-items a {
  font-weight: 600;
  font-size: large;
  color: black;
  text-decoration: none;
  transition: 0.1s ease-in-out;
}
.navbar-items a:hover {
  text-decoration: underline;
  transition: 0.1s ease-in-out;
}
.navbar-items > ul {
  list-style-type: none;
}
.navbar-items > ul > li {
  display: inline-block;
  padding: 0 1.2vw;
}

/* Dropdown */
.dropbtn {
  background-color: #ffffff;
  color: white;
  padding: 14px 10px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  transition: 0.1s ease-in-out;
  border: 3px solid black;
  color: black;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 5px;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: 0.1s ease-in-out;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: rgb(70, 65, 55);
  color: white;
}
