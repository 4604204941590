.projects-wrapper {
  width: 94vw;
  /* height: 100vh; */
  background-color: rgb(255, 238, 207);
  padding: 10vh 0;
  text-align: center;
  border: 5px solid rgb(49, 44, 35);
  border-radius: 10px;
  overflow-x: hidden;
  /* display: flex;
  justify-content: center;
  flex-direction: column; */
}
.projects-main {
  width: 94vw;
  /* background-color: white; */
  flex-direction: row;

  align-items: center;

  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-wrap: nowrap; */
}
.cards {
  width: 90vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card {
  margin: 2.5vw;
  padding: 1vh;
  border-radius: 10px;
  /* background-image:linear-gradient(to right, #F7797D,#FBD786,#C6FFDD) ; */
  background-color: rgb(255, 255, 255);
  border: 5px solid rgb(36, 49, 11);
  transition: 0.2s ease-in-out;
  box-shadow: 8px 8px 4px grey;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card:hover {
  scale: 1.08;
  box-shadow: 1px 1px 1px grey;
}
.card-name {
  font-weight: 600;
}
.card-description {
  padding: 0 10px;
  text-align: center;
  font-weight: 400;
  font-size: 1.5em;
}
.card a {
  font-size: 1.3em;
  color: black;
}
