.mission-main {
  width: 100vw;
  overflow-x: hidden;
  text-align: center;
}

.mission-main > h1 {
  text-align: center;
  margin-top: 15vh;
}

.mission-main > h3 {
  text-align: center;
  width: 60vw;
  position: relative;
  left: 20vw;
  margin: 8vh 0;
}

.mission-cards {
  display: flex;
  width: 100vw;
  align-content: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  /* padding: 0 4vw; */
  margin: 10vh 0;
}

.mission-card {
  width: 85vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  margin: 6vh 0;
  position: relative;
  border-radius: 40px;
}
/* #mission-card-white {
  background-color: blue;
} */

.mission-card-pic > img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 1vh;
}

.mission-card-black {
  color: rgb(0, 0, 0);
  background-color: #ffcbd45f;
  box-shadow: 8px 5px 10px rgba(0, 0, 0, 0.2);
  font-size: large;
  font-weight: 500;
  justify-content: center;
}

.mission-card-text > p,
.mission-card-pic {
  position: relative;
}
.mission-card-text {
  width: 70vw;
}

.how-started {
  width: 80vw;
  text-align: center;
  background-color: rgb(255, 238, 207);
  border-radius: 25px;
  border: 10px dashed rgb(255, 183, 81);
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; */
}

.members {
  width: 70vw;
  padding: 10vh 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
#about {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.member {
  width: 300px !important;
}
.member img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}
