.home-wrapper {
  width: 100vw;
  height: 100vh;
  background-color: white;
  color: rgb(52, 52, 52);
}
.home-main {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* display: flex;
    justify-content: center;
    align-items: center; */
}
.home-text {
  position: relative;
  left: 1vw;
  top: 25vh;
  background-color: rgb(255, 213, 220);
  border-radius: 25px;
  padding: 20px;
  border: 5px solid black;
  box-shadow: 5px 5px 2px black;
  transition: 0.2s ease-in-out;

  width: 33vw;
  aspect-ratio: 3/1.5;
  /* overflow: hidden; */
  word-break: break-all;
}
/* .home-text h1,
.home-text p {
  font-size: calc(10px + 1.3vw);
} */
.home-text:hover {
  scale: 1.03;
}
.home-main img {
  width: 100vw;
  height: 130vh;
  object-fit: cover;
}
.blue-text {
  padding: 5px 10px;
  border-radius: 6px;
  background-color: rgb(190, 190, 251);
  text-decoration: underline;
  text-decoration-color: rgb(57, 57, 98);
  transition: 0.15s ease-in-out;
}
.purple-text {
  padding: 5px 10px;
  border-radius: 6px;
  background-color: rgb(196, 5, 210);
  text-decoration: underline;
  color: white;
  text-decoration-color: white;
  transition: 0.15s ease-in-out;
}
.blue-text:hover,
.purple-text:hover {
  background-color: rgb(218, 218, 218);
  text-decoration-color: rgb(88, 88, 88);
  color: rgb(88, 88, 88);
  cursor: pointer;
}
