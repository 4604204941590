.project-main {
  /* background-image: linear-gradient(to right, #f56f9c, #f7bb97); */
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 20vh 0;
}
.project-text {
  width: 20vw;
}
.project-images {
  position: relative;
  width: 50vw;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.project-images img {
  height: auto;
}

.imgOne,
.imgTwo,
.imgThree {
  position: relative;
  background-color: white;
  border-radius: 10px;
  object-fit: fill;
  box-shadow: 8px 8px 5px rgb(0, 0, 0);
}
.imgOne {
  /* border: 3px solid wheat; */
  left: 16vw;
  z-index: 10;
  width: 28vw;
}
.imgTwo {
  left: 5vw;
  z-index: 9;
  width: 25vw;
}
.imgThree {
  left: -5vw;
  z-index: 8;
  width: 20vw;
}
.project-data h1 {
  text-align: center;
}
.available-data {
  margin: 10px auto;
  width: 75vw;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}
.member {
  width: 25vw;
  text-align: center;
}
